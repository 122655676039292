<template>
  <div class="v-modal" style="z-index: 1000">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: "ws-zhezhaoceng"
};
</script>

<style scoped lang="scss">
.v-modal{
  background-color: rgba(0,0,0,0.2);
  opacity:1,
}
</style>
