<template>
  <my-dv-box class="ws-title" width="424px" height="33px">
    <my-dv-box left="37px" top="6px">{{title}}</my-dv-box>
    <my-dv-box class="more" v-if="right"
               right="0" top="8px"  @click="tclick">{{right}}   <el-icon name="arrow-right" ></el-icon>
    </my-dv-box>
    <my-dv-box class="more" v-if="$slots?.vees"
               right="0" top="0" >
      <slot name="vees">111</slot>
    </my-dv-box>

  </my-dv-box>
</template>

<script>
export default {
  name: "ws-title",
  props:{
    // eslint-disable-next-line vue/require-prop-type-constructor
    title:"",
    // eslint-disable-next-line vue/require-prop-type-constructor
    right:""
  },
  methods:{
    tclick(){
      this.$emit('click');
    },
  },
  mounted() {
  }
};
</script>

<style scoped lang="scss">
.ws-title{
  background-image: url("~@/assets/images/ws-title.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  font-size: 20px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 1em;
  .more{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 400;
    color: #FFFFFF;
    cursor: pointer;
  }
}
</style>
