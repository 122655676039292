<template>
  <el-dropdown @command="choosefun">
    <div class="chufa" >
      {{choosetext}}<i class="el-icon-arrow-down el-icon--right"></i>
    </div>
    <el-dropdown-menu  slot="dropdown">
      <el-dropdown-item v-for="i in zhenlist" :command="i.areaId" :key="i.areaId">{{i.areaName}}</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>``
import {mapState} from "vuex";

export default {
  name: "xialakuang",
  props:{'value':0},
  computed:{
    choosetext(){
      let text='请选择'
      this.zhenlist.map(i=>{
        if (i.areaId===this.choose){
            text=i.areaName
        }
      })
      return  text
    },
    ...mapState({
      zhenlist: state =>state.zhenlist
    }),
  },
  data(){return {
    choose:0,
    list:[
      {name:'孟河镇',key:1},
      {name:'春江镇',key:2},
      {name:'西夏墅镇',key:3},
      {name:'罗溪镇',key:4},
      {name:'新桥镇',key:5},
      {name:'薛家镇',key:6},
      {name:'龙虎塘街道',key:7},
      {name:'河海街道',key:8},
      {name:'三井街道',key:9},
    ],
  }},
  mounted() {
    this.choose=this.value
  },
  methods:{
    choosefun(e){
      this.choose=e
      this.$emit('input',e)
    }
  }
};
</script>

<style scoped lang="scss">
.chufa{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 33px;
  background: linear-gradient(182deg, #2981B7 0%, rgba(20,68,105,0) 100%);
  font-size: 14px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
}
</style>
