<template>
<my-dv-box class="chanzhiqushi" width="423px"  height="238px"  top="732px" left="0" >
  <ws-title title="产业产值趋势图(万元）">
    <xialakuang slot="vees" v-model="choosevalue"></xialakuang>
  </ws-title>
  <my-dv-box  width="410px" height="167px" x-align="left" y-align="bottom" >
    <div ref="bar" style="width: 100%;height: 100%"></div>
  </my-dv-box>
</my-dv-box>
</template>

<script>
import * as echarts from "echarts";
import WsTitle from "@/views/component/ws/ws-title";
import Xialakuang from "@/views/com/xialakuang";
import {dataSheet} from "@/utils/api";

export default {
  name: "chanzhiqushi",
  components: {Xialakuang, WsTitle},
  data(){return {
    mapChart:null,
    choosevalue:1,
    xLabel:[],
    yLabel:[],
    type:1,
    res:[
      {industryOutputValue:86,year:'2018年'},
      {industryOutputValue:90,year:'2019年'},
      {industryOutputValue:100,year:'2020年'},
      {industryOutputValue:96,year:'2021年'},
      {industryOutputValue:110,year:'2022年'},
    ],
  }},
  mounted() {
    // this.$http.get('/gongsidaping/gk/outputValue/'+this.type).then(res=>{
    //   this.res=res.data
      this.$nextTick(()=>{
        this.mapChart = echarts.init(this.$refs.bar);
        this.dataSheet()
      })
    // })
  },
  watch:{
    choosevalue(){
      this.dataSheet()
    }
  },
  methods:{
    dataSheet(){
      dataSheet(this.choosevalue,3).then(res=>{
        this.xLabel=res.data.industryTrend.x
        this.yLabel=res.data.industryTrend.y
        this.initdata()
      })
    },
    setdata(){
      this.xLabel=[]
      this.yLabel=[]
        this.res.map(i=>{
          this.xLabel.push(i.year)
          this.yLabel.push(i.industryOutputValue)
        })
      this.initdata()
    },
    initdata(){
      /**
       *
       * 作者: missMa
       * 描述: 柱状图
       *
       */
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            }
          },
          formatter: (prams) =>{
            return prams[0].axisValueLabel+"产值：<br/>" + prams[0].data+'万元'
          }
        },
        animation: false,
        grid: {
          top: '10',
          left:0,
          right:0,
          containLabel: true,
          bottom: '0', //也可设置left和right设置距离来控制图表的大小
        },
        xAxis: {
          data: this.xLabel,
          axisTick: {
            alignWithLabel: true
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.1)'
            }
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF'
          },
        },
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            min:0,
            splitLine:{
              show:true,
              lineStyle:{
                color:'rgba(66, 213, 255, 0.1)'
              }
            },
            axisTick: {
              show: false,
            },
            axisLine:{
              show:true,

              lineStyle:{
                color:'rgba(66, 213, 255, 0.1)'
              }
            },
            axisLabel:{
              fontSize: 12,
              color:'#FFFFFF',
              // formatter:(e)=>{
              //   return this.fengxiang[e.toString()]
              // },
            },
          },
        ],
        series: [
          {
            // 值
            name: '产值',
            type: 'bar',
            barWidth: 23,
            itemStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color: 'rgba(52, 117, 193, 1)',
                  },
                  {
                    offset: 1,
                    color: 'rgba(53, 118, 191, 0)',
                  },
                ]),
              },
            },
            data: this.yLabel,

            label: {
              show: false,
              position: 'top',
              distance: 10,
              fontSize: 16,
              fontFamily:'DIN',
              color: '#ffffff',
              zlevel: 4,
              z: 10,
              formatter:'{c}%'
            },
          },
          {
            type: "scatter",
            z: 10,
            zlevel: 2,
            symbol:'rect',
            symbolSize: [23,3],
            itemStyle: {
              color: '#00D8FD',
            },
            silent: true,
            data: this.yLabel,
          }
        ],
      };
      this.mapChart.setOption(option)
    },
  },
};
</script>

<style scoped lang="scss">
.chanzhiqushi{
}
</style>
