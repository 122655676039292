import { mapState } from 'vuex';
export default {
    data(){
        return {
            title:'',
        }
    },
    created() {
        setTimeout(() =>this.setTitle(),111)
    },
    watch:{
        farmId(){
            setTimeout(() =>this.setTitle(),111)
        }
    },
    methods:{
        setTitle(){
            let title=''
            if (this.farmId===0){
                title= "新北区数字化生态水产养殖可视化平台"
            }
            if (this.farmId===1){
                title= "洲洋家庭农场数据大屏"
            }
            if (this.farmId===2){
                title= "硕丰水产数字养蟹数据大屏"
            }
            if (this.farmId===3){
                title= "渔悦水产数字养蟹数据大屏"
            }
            if (this.farmId===4){
                title= "丁氏水产数字养蟹数据大屏"
            }
            if (this.farmId===5){
                title= "朱胜钢水产数字养蟹数据大屏"
            }
            if (this.farmId===6){
                title= "新峰水产数字养蟹数据大屏"
            }
            this.title=document.title= title
        }
    },
    computed:{
        ...mapState({
            farmId: state =>state.farmId
        }),
    }
}
