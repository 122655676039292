<template>
<my-dv-box class="tabbar" z-index="11" width="100%" height="70px" x-align="center" y-align="bottom">
</my-dv-box>
</template>
<script>
export default {
  name: "Tabbar",
  methods:{

  },
};
</script>

<style scoped lang="scss">
.tabbar{
  background: url("~@/assets/images/di .png") no-repeat;
  .tab{
    width: 100%;
    .tabitem{
      margin: 0 35px;
      width: 210px;
      height: 70px;
      padding: 0 20px;
      cursor: pointer;
      background-image: linear-gradient(
              180deg,
              rgba(14, 109, 233, 0.28) 0,
              rgba(14, 109, 233, 0.28) 0,
              rgba(3, 169, 255, 0.28) 46.923828%,
              rgba(3, 169, 255, 0.28) 100%
      );
      font-size: 18px;
      font-family: Hiragino Sans GB;
      font-weight: normal;
      color: #00E8EE;
      &.selected{
        border-bottom:3px solid #00FFFF;
      }
    }
  }
}
</style>
