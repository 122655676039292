<template>
<div class="webpdongtu">
  <el-image class="image1" :src="require('@/assets/images/2132144365ds.png')"></el-image>
  <el-image class="image1 img2" :src="require('@/assets/images/底座2.webp')"></el-image>
  <slot class="imaget"></slot>
</div>
</template>

<script>
export default {
  name: "ws-webpdongtu"
};
</script>

<style scoped lang="scss">
.webpdongtu{
  position: relative;
  display: block;
  height: 94px;
  overflow: hidden;
  width: 90px;
  position: relative;
  .imaget{
    width: 33px;
    height: 50px;
    position: absolute;
    top: 2px;
    left: 33px;
  }
  .image1{
    width: 100px;
    &.img2{
      transform: translateX(0) translateY(-102%);
    }
  }
}
</style>
