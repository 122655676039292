import { render, staticRenderFns } from "./geojsonmap-xinbei-svg.vue?vue&type=template&id=424764ce&scoped=true&"
import script from "./geojsonmap-xinbei-svg.vue?vue&type=script&lang=js&"
export * from "./geojsonmap-xinbei-svg.vue?vue&type=script&lang=js&"
import style0 from "./geojsonmap-xinbei-svg.vue?vue&type=style&index=0&id=424764ce&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "424764ce",
  null
  
)

export default component.exports