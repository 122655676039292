<template>
  <my-dv-box width="11px" height="22px"  left="29px" class="xbqleft">
    <kehuzhuti v-if="customerMain" :customerMain="customerMain"></kehuzhuti>
    <weishuijiance v-if="tailwaterDetectionInfo" :tailwaterDetectionInfo="tailwaterDetectionInfo"></weishuijiance>
    <rtoshebei></rtoshebei>
  </my-dv-box>
</template>

<script>
import Kehuzhuti from "@/views/component/kehuzhuti";
import Weishuijiance from "@/views/com/weishuijiance";
import Rtoshebei from "@/views/com/rtoshebei";
export default {
  name: "xbqleft",
  props:{
    customerMain:null,
    tailwaterDetectionInfo:null,
  },
  components: {Rtoshebei, Weishuijiance, Kehuzhuti}
};
</script>

<style scoped lang="scss">
.xbqleft{
}
</style>
