<template>
  <my-dv-box class="shengchanhuanjing" top="750px" width="424px" height="205px">
    <ws-title title="RTO设备监测"></ws-title>
    <my-dv-box top="60px">
      <div class="bottomg">
        <div class="bottomg-item " :class="{active:active===1}" @click="active=1">
          <div>水质</div>
          <div>检测</div>
        </div>
        <div class="bottomg-item " :class="{active:active===2}" @click="active=2">
          <div>气象</div>
          <div>设备</div>
        </div>
        <div class="bottomg-item " :class="{active:active===3}" @click="active=3">
          <div>水位</div>
          <div>监测</div>
        </div>
        <div class="bottomg-item " :class="{active:active===4}" @click="active=4">
          <div>投喂船</div>
          <div>设备</div>
        </div>
        <div class="bottomg-item " :class="{active:active===5}" @click="active=5">
          <div>监控</div>
          <div>设备</div>
        </div>
      </div>
    </my-dv-box>
    <my-dv-box top="140px" class="danwei" v-if="detail">总投放设备：{{detail.total}}{{showdanwei}}</my-dv-box>
    <my-dv-box v-if="detail" y-align="bottom" width="400px" height="20px">
      <div class="line">
        <div class="ume">
          <el-image :src="require('@/assets/images/zaixian.png')" style="width: 19px;height: 19px"></el-image>
          在线:{{detail.online}}
        </div>
        <div class="ume">
          <el-image :src="require('@/assets/images/chaobiao.png')" style="width: 19px;height: 19px"></el-image>
          超标:{{detail.overproof}}
        </div>
        <div class="ume">
          <el-image :src="require('@/assets/images/lixian.png')" style="width: 19px;height: 19px"></el-image>
          离线:{{detail.offline}}
        </div>
        <div class="ume">
          <el-image :src="require('@/assets/images/weihu.png')" style="width: 19px;height: 19px"></el-image>
          维护:{{detail.maintain}}
        </div>
      </div>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import WsTitle from "@/views/component/ws/ws-title";
import {regioneviceMonitoring} from "@/utils/api";
export default {
  name: "rtoshebei",
  components: { WsTitle},
  data(){
    return {
      active:1,
      detail:{
        total:0,
        online:0,
        overproof:0,
        offline:0,
        maintain:0,
      },
    }
  },
  mounted() {
    this.rot()
    this.timer=setInterval(this.rot,1000*60)
  },
  beforeDestroy() {
    clearInterval(this.timer)
    this.timer=null
  },
  watch:{
    active(){
      this.rot()
    },
  },
  computed:{
    showdanwei(){
      let d='台'
      if (this.active===3||this.active===2||this.active===1){
        d='套'
      }
      return d;
    }
  },
  methods:{
    rot(){
      regioneviceMonitoring(this.active).then(res=>{
        this.detail={
          total:res.data.totalDeliveryEquipment,
          online:res.data.onlineEquipment,
          overproof:res.data.outOfStandardEquipment,
          offline:res.data.offlineEquipment,
          maintain:res.data.numberOfMaintenanceDevices,
        }
      })
    },
  },
};
</script>

<style scoped lang="scss">
.shengchanhuanjing{
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 500;
  color: #FFFFFF;
  .line{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .bottomg{
    width: 392px;
    height: 50px;
    display: flex;
    justify-content:space-between;
    .bottomg-item{
      width: 53px;
      height: 50px;
      background: url("~@/assets/images/23gfgfdy.png") no-repeat;
      background-size: 100% 100%;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #999999;
      line-height: 1.1em;
      cursor: pointer;
      &.active{
        background: url("~@/assets/images/gfhfrweytr.png") no-repeat;
        background-size: 100% 100%;
        color: #FFFFFF;
      }
    }
  }
}
</style>
