<template>
  <my-dv-box class="mianjipaihang" width="916px"  height="200px"  top="776px" left="506px" >
    <ws-title title="产业面积排行(亩）" ></ws-title>
    <my-dv-box right="30px" top="00px">单位：亩</my-dv-box>
    <my-dv-box  width="100%" height="151px" x-align="left" y-align="bottom" >
      <div ref="bar" style="width: 100%;height: 100%"></div>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import WsTitle from "@/views/component/ws/ws-title";
import * as echarts from "echarts";
export default {
  name: "mianjipaihang",
  components: {WsTitle},
  props:{
    industrialArea:{
      default:null,
    }
  },
  data(){return {
    mapChart:null,
    xLabel:[],
    yLabel:[],
    res:[
      {area:86,year:'1村'},
      {area:90,year:'2村'},
      {area:100,year:'3村'},
      {area:96,year:'4村'},
      {area:110,year:'5村'},
    ],
  }},
  mounted() {
    this.$nextTick(()=>{
      this.mapChart = echarts.init(this.$refs.bar);
      this.setdata()

    })
  },
  methods:{
    setdata(){
      this.xLabel=[]
      this.yLabel=[]
      this.xLabel=this.industrialArea.x[0]
      this.yLabel=this.industrialArea.y
      this.initdata()
    },
    initdata(){
      let option = {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'line',
            lineStyle: {
              opacity: 0
            }
          },
          formatter: (prams) =>{
              return prams[0].axisValueLabel+"产值：<br/>" + prams[0].data+'亩'
          }
        },
        animation: false,
        grid: {
          top: '10',
          left:0,
          right:0,
          containLabel: true,
          bottom: '0', //也可设置left和right设置距离来控制图表的大小
        },
        xAxis: {
          data: this.xLabel,
          axisTick: {
            alignWithLabel: true
          },
          axisLine:{
            show:true,
            lineStyle:{
              color:'rgba(66, 213, 255, 0.01)'
            }
          },
          axisLabel:{
            fontSize: 14,
            color:'#FFFFFF',
            interval:0
          },
        },
        yAxis: [
          {
            type: 'value',
            gridIndex: 0,
            min:0,
            splitLine:{
              show:true,
              lineStyle:{
                color:'rgba(66, 213, 255, 0.05)'
              }
            },
            axisTick: {
              show: false,
            },
            axisLine:{
              show:true,
              lineStyle:{
                color:'rgba(66, 213, 255, 0.1)'
              }
            },
            axisLabel:{
              fontSize: 12,
              color:'#FFFFFF',
              // formatter:(e)=>{
              //   return this.fengxiang[e.toString()]
              // },
            },
          },
        ],
        series: [
          {
            // 值
            name: 'mm',
            type: 'line',
            smooth: true,
            symbolSize:6,
            itemStyle: {
              color: 'rgba(0, 216, 253, 1)',
            },
            areaStyle: {
              normal: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                  offset: 0,
                  color: 'rgba(1, 84, 191, 0)'
                }, {
                  offset: 0.8,
                  color: 'rgba(10, 92, 188, 1)'
                }], false),
                shadowColor: 'rgba(0, 0, 0, 0.1)',
                shadowBlur: 10
              },
            },
            data: this.yLabel,

          },
        ],
      };
      this.mapChart.setOption(option)
    },
  },
};
</script>

<style scoped>

</style>
