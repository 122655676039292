<template>
  <my-dv-box class="kehuzhuti" top="84px" width="424px" height="153px">
    <ws-title title="客户主体" ></ws-title>


    <my-dv-box  width="415px" height="92px" y-align="bottom">
      <my-dv-box @click="showdialog(10)" class="currr"  style="cursor: pointer;transform: translateX(0);" x-align="left" height="92px" width="122px" >
        <my-dv-box  x-align="center" content-align="center" width="61px" height="29px" class="title">
          个体户
        </my-dv-box>
        <my-dv-box x-align="center" content-align="center" top="40px" class="value">
          {{customerMain.customer}}
        </my-dv-box>
        <my-dv-box y-align="bottom" z-index="-1" width="122px" height="54.3px">
          <ws-xuanzhuan title="" value=""></ws-xuanzhuan>
        </my-dv-box>
      </my-dv-box>

      <my-dv-box @click="showdialog(6)" class="currr" style="cursor: pointer" x-align="center" height="92px" width="122px">
        <my-dv-box x-align="center" content-align="center" width="75px" height="29px" class="title">
          农业公司
        </my-dv-box>
        <my-dv-box x-align="center" content-align="center" top="40px" class="value">
          {{customerMain.corporation}}
        </my-dv-box>
        <my-dv-box y-align="bottom" z-index="-1" width="122px" height="54.3px">
          <ws-xuanzhuan title="" value=""></ws-xuanzhuan>
        </my-dv-box>
      </my-dv-box>
      <my-dv-box @click="showdialog(7)" class="currr"  style="cursor: pointer;transform: translateX(0%)" x-align="right" height="92px" width="122px"  >
        <my-dv-box x-align="center" content-align="center" width="75px" height="29px" class="title">
          示范基地
        </my-dv-box>
        <my-dv-box x-align="center" content-align="center" top="40px" class="value">
          {{customerMain.demonstrationBase}}
        </my-dv-box>
        <my-dv-box y-align="bottom" z-index="-1" width="122px" height="54.3px">
          <ws-xuanzhuan title="" value=""></ws-xuanzhuan>
        </my-dv-box>
      </my-dv-box>
    </my-dv-box>
    <ws-zhezhaoceng v-if="list.length>0">
      <my-dv-box class="showrefds" width="916px" height="464px" x-align="center" y-align="middle" >
          <my-dv-box class="showmarks flex-center" top="50px"  width="526px"
                     height="66px" x-align="center">{{tkkbt}}</my-dv-box>
        <my-dv-box right="10PX" @click="list=[]" top="16px" class="srwett"><el-image :src="require('@/assets/images/fsdfefretwert.png')"></el-image></my-dv-box>
        <my-dv-box width="813px" height="265px" top="136px" x-align="center">
          <div class="list-1 flex-between">
            <div class="line-c-1 flex-center">{{erwwfqe}}</div>
            <div class="line-c-2 flex-center">所属地区</div>
            <div class="line-c-3 flex-center">法人姓名</div>
            <div class="line-c-4 flex-center">联系方式</div>
          </div>
          <div class="alllist">
            <my-marquee class="container" :disabled="list.length<=5" :speed="0.5"  :data="list">
              <div class="list-2 flex-between" v-for="item in list">
                <div class="line-c-1 flex-center">{{item.name}}</div>
                <div class="line-c-2 flex-center">{{item.affiliatedUnit}}</div>
                <div class="line-c-3 flex-center">{{item.ceoName}}</div>
                <div class="line-c-4 flex-center">{{item.phone}}</div>
              </div>
            </my-marquee>
          </div>


        </my-dv-box>
      </my-dv-box>
    </ws-zhezhaoceng>

  </my-dv-box>
</template>

<script>
import WsTitle from "@/views/component/ws/ws-title";
import WsXuanzhuan from "@/views/component/ws/ws-xuanzhuan";
import Xialakuang from "@/views/com/xialakuang";
import WsZhezhaoceng from "@/views/component/ws/ws-zhezhaoceng";
import {regionvillage, regionvillageAll} from "@/utils/api";
export default {
  name: "kehuzhuti",
  components: {WsZhezhaoceng, Xialakuang, WsXuanzhuan, WsTitle},
  data(){
    return {
      list:[],
      active:10,
      res:{
        geti:10,
        ncompany:10,
        base:10,
      }
    }
  },
  props:{
    customerMain:null,
  },
  computed:{
    tkkbt(){
      if (this.active===10){
        return '个体户'
      }
      if (this.active===6){
        return '农业公司'
      }
      if (this.active===7){
        return '示范基地'
      }
    },
    erwwfqe(){
      if (this.active===10){
        return '个体户名称'
      }
      if (this.active===6){
        return '公司名称'
      }
      if (this.active===7){
        return '基地名称'
      }
    },
  },
  methods:{
    showdialog(type) {
      regionvillageAll(type).then((result) => {
        this.list=result.data;
        this.active=type
      });
    },
  },
  mounted() {
    // this.$http.get('/gongsidaping/gk/khzt').then(res=>{
    //   this.res=res.data
    // })
  }
};
</script>

<style scoped lang="scss">
.kehuzhuti{
  .currr{
    cursor: pointer;
  }
  .title{
    font-size: 14px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    background-image: url("~@/assets/images/getihutitle.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    line-height: 30px;
  }
  .value{
    font-size: 18px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #1CFEFF;
  }
}
.showrefds{
  background: url("~@/assets/images/rtgfdshfgh.png") no-repeat;
  background-size: 100% 100%;
  .showmarks{
    background: url("~@/assets/images/43teadfsfare.png") no-repeat;
    background-size: 100% 100%;
    font-size: 24px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #FFFFFF;
    border:0;
  }
  .list-1{
    width: 100%;
    height: 48px;
    background: #0075A9;
  }
  .list-2{
    height: 42px;
    border-bottom: 1px solid rgba(255,255,255,0.2);
    border-right: 1px solid rgba(255,255,255,0.2);
    border-left: 1px solid rgba(255,255,255,0.2);
  }
  .line-c-1{
    height: 100%;
    width: 283px;
    border-top: 1px solid rgba(255,255,255,0.2);
    border-right: 1px solid rgba(255,255,255,0.2);
  }
  .line-c-2{
    height: 100%;
    width: 178px;
    border-top: 1px solid rgba(255,255,255,0.2);
    border-right: 1px solid rgba(255,255,255,0.2);
  }
  .line-c-3{
    height: 100%;

    width: 118px;
    border-right: 1px solid rgba(255,255,255,0.2);
    border-top: 1px solid rgba(255,255,255,0.2);
  }
  .line-c-4{
    height: 100%;
    width: 231px;
    border-top: 1px solid rgba(255,255,255,0.2);
  }
  .alllist{
    height: calc(265px - 48px);
    overflow: hidden;
  }
}
</style>
