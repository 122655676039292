<template>
<div class="ws-bottom" :class="{active}" style="position: relative" @click="btclick" :style="{width,height,marginLeft,marginRight}">
    {{text}}
</div>
</template>

<script>
export default {
  name: "ws-buttom",
  props:{
    text:{
      default:'',
      type:String,
    },
    width:{
      default:'82px',
      type:String,
    },
    marginLeft:{
      default:'0',
      type:String,
    },
    active:{
      type:Boolean,
      default:true,
    },
    marginRight:{
      default:'0',
      type:String,
    },
    height:{
      default:'31px',
      type:String,
    }
  },
  methods:{
    btclick(e){
      this.$emit('click',e)
    },
  }
};
</script>

<style scoped lang="scss">
.ws-bottom{
  background-image: url("~@/assets/images/ws-buttom-grey.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: inline-flex;
  justify-content: center;
  padding-bottom: 2px;
  align-items: center;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #FFFFFF;
  opacity: 0.7;
  cursor: pointer;
  &.active{
    opacity: 1;
  }
}
</style>
