<template>
  <div class="home">
    <xbqleft
        :customerMain="customerMain"
        :tailwaterDetectionInfo="tailwaterDetectionInfo"
    ></xbqleft>
    <statics v-if="area" :area="area"></statics>
    <my-dv-box left="523px" top="232px">
      <ws-buttom text="智能监控" @click="gotohe"></ws-buttom>
    </my-dv-box>
    <geojsonmap-xinbei></geojsonmap-xinbei>
    <mianjipaihang v-if="industrialArea" :industrialArea="industrialArea"></mianjipaihang>
    <my-dv-box right="16px"   height="100%"  width="424px" >
      <mianjiqushi></mianjiqushi>
      <chanliangqushi></chanliangqushi>
      <chanzhiqushi></chanzhiqushi>
    </my-dv-box>
    <Tabbar></Tabbar>
  </div>
</template>

<script>
import Header from "@/views/Header";
import Tabbar from "@/views/Tabbar";
import Xbqleft from "@/views/com/xbqleft";
import Statics from "@/views/com/statics";
import Mianjiqushi from "@/views/com/mianjiqushi";
import Chanliangqushi from "@/views/com/chanliangqushi";
import Chanzhiqushi from "@/views/com/chanzhiqushi";
import GeojsonmapXinbei from "@/views/com/geojsonmap-xinbei";
import Mianjipaihang from "@/views/com/mianjipaihang";
import WsButtom from "@/views/component/ws/ws-buttom";
import {bigData} from "@/utils/api";
import api from '@/utils/request'

export default {
  name: "index",
  components:{
    WsButtom,
    Mianjipaihang,
    GeojsonmapXinbei, Chanzhiqushi, Chanliangqushi, Mianjiqushi, Statics, Xbqleft, Tabbar,    Header},
  data(){return {area:null,areaTrend:null,customerMain:null,tailwaterDetectionInfo:null,industrialArea:null}},
  mounted() {
    bigData().then(res=>{
      this.area=res.data.area
      this.areaTrend=res.data.areaTrend
      this.customerMain=res.data.customerMain
      this.tailwaterDetectionInfo=res.data.tailwaterDetectionInfo
      this.industrialArea=res.data.industrialArea
    })
  },
  methods:{
    gotohe(){
      this.$router.push('/camera')
    }
  }
};
</script>

<style scoped lang="scss">

</style>
