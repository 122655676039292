<template>
  <div :style="{width: '100%',height: '100%'}">
    <img ref="image" :src.sync="src" v-if="src!==null"  style="width: 100%;height: 100%;"/>
    <canvas ref="a"  style="width: 100%;height: 100%;display:none"></canvas>
    <div style="display:none">
      <img ref="conf0" @load="loadingIndex++" src="@/assets/images/gailun-yuanpan.png">
      <img ref="conf1" @load="loadingIndex++" src="@/assets/images/gailun-guangquan.png">
    </div>
  </div>
</template>

<script>
import zhuanImage from '@/assets/images/gailun-yuanpan.png'
export default {
  name: "ws-xuanzhuan",
  data(){
    return {
      widthratate:1.5,//图像放大比例
      yrotate:0.5,
      width:350,
      height:0,
      loadingIndex:0,
      canvas:null,
      ctx:null,
      src:null,
      zhuanImage,
      jiaodu:0,
      requestId:null,
    }
  },
  beforeDestroy() {
    if (this.requestId){
      window.cancelAnimationFrame(this.requestId)
      this.requestId=null
    }
  },
  mounted() {
    this.width*=this.widthratate;
    this.height=this.width*(1-this.yrotate);
    this.canvas = this.$refs.a;
    this.ctx = this.canvas.getContext("2d");
    this.canvas.width = this.width;
    this.canvas.height = this.height;
    this.init();
  },
  methods:{
    init(){
      if (this.loadingIndex===2){
        this.jiaodu=(this.jiaodu+6)%360;
        this.ctx.clearRect(0,0,this.canvas.width,this.canvas.height)
        this.loadyuanimage();
        this.loadyuanimage2();
        // this.ctxdraw();
        this.canvas2image();
      }
      this.requestId=window.requestAnimationFrame(this.init);
    },
    ctxdraw(){
      const canvas = document.createElement("canvas", {
        alpha:true,
      });
      const ctx = canvas.getContext("2d");

      const height=canvas.height=canvas.width = 200;
      const width = height;
      ctx.lineWidth = 3;
      let gradient = ctx.createLinearGradient(0, 0, 300, 0);
      gradient.addColorStop(0, 'rgba(28, 254, 255, 1)');
      gradient.addColorStop(1, 'rgba(28, 254, 255, 1)');
      ctx.strokeStyle = gradient;
      ctx.beginPath();
      ctx.arc(width/2, height/2, width/2, -45*Math.PI/180, 225*Math.PI/180);
      ctx.stroke();
      this.centerDraw(canvas,0,5)

    },
    loadyuanimage(){
      const canvas = document.createElement("canvas", {
        alpha:false,
      });
      const ctx = canvas.getContext("2d");
      const width=canvas.width = this.$refs.conf0.width*this.widthratate;
      const height=canvas.height = this.$refs.conf0.height*this.widthratate;
      // 先位移坐标到中心
      ctx.translate(width / 2, height / 2);
      // 旋转90度
      ctx.rotate(-this.jiaodu * Math.PI / 180);
      // 此时按照旋转后的尺寸
      // 把定位中心移动到左上角
      ctx.translate(-1 * height / 2, -1 * width / 2);
      ctx.drawImage(this.$refs.conf0, 0, 0,height, width,0, 0,height*this.widthratate, width*this.widthratate);
      // 坐标系还原到初始
      ctx.setTransform(1, 0, 0, 1, 0, 0);
      this.centerDraw(canvas)
    },
    loadyuanimage2(){
      const canvas = document.createElement("canvas", {
        alpha:true,
      });
      const ctx = canvas.getContext("2d");
      const width=canvas.width = this.$refs.conf1.width*this.widthratate;
      const height=canvas.height = this.$refs.conf1.height*this.widthratate;
      ctx.drawImage(this.$refs.conf1, 0, 0,height, width,0, 0,height*this.widthratate, width*this.widthratate);
      // 坐标系还原到初始
      this.centerDraw(canvas)
    },
    centerDraw(canvas,x=0,y=0){
      this.ctx.save();
// 来来来，垂直翻转下
      this.ctx.scale(1, 0.4);
// 中心点填充图像
      this.ctx.drawImage(canvas,(this.width-canvas.width) / 2 + x,y);
      this.ctx.scale(1, 1);
// 恢复状态，不要影响接下来的绘制
      this.ctx.restore();
    },

    canvas2image(){
      this.src = this.canvas.toDataURL("image/svg");
    },

  }
};
</script>

<style scoped>

</style>
