<template>
  <my-dv-box class="header" z-index="11" left="0" top="18px" width="1920px" height="70px">
    <my-dv-box class="h-left" top="4px" left="0" height="66px" width="668px">
      <!--      <my-dv-box left="28px" top="-15px" width="114px" height="31px">-->
      <!--      <el-image style="width: 100%;height:100%;" :src="require('@/assets/images/logo.png')"/>-->
      <!--      </my-dv-box>-->
    </my-dv-box>
    <my-dv-box class="h-center flex-center" x-align="center" y-align="middle"
               content-align="center" height="66px" width="630px">
      <div  class="text">{{title}}</div>
    </my-dv-box>
    <my-dv-box class="h-right" top="4px" right="0" height="66px" width="672px">
      <my-dv-box right="28px" top="-15px" class="text">
        <my-date
            format='YYYY-MM-DD  dddd  HH:mm:ss'
            tick class="text" style="margin-right: 10px"></my-date>
        <el-image v-if="weather" style="width: 21px;height: 17px;margin-right: 10px" :src="weatherimg"/>
        <span v-if="weather">{{weather.weather}}&nbsp;&nbsp;&nbsp;{{weather.temp}}℃</span>
      </my-dv-box>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import title from "@/utils/minxins/title";
import {nuoyaweather} from "@/utils/api";

export default {
  name: "Header",
  computed:{
    weatherimg(){
      return this.weather?.wea_img?`https://xinbeimenghe.oss-cn-shanghai.aliyuncs.com/${this.weather.wea_img}.svg`:''
    },
    backurl(){
      return this.$route.query?.back?this.$route.query.back:(this.$route.meta?.backurl?this.$route.meta.backurl:'')
    },
  },
  data(){
    return {
      weather:null,
    }
  },
  mixins:[title],
  mounted() {
    nuoyaweather().then(result=>{
      this.weather=result.data;
    })
  },
  methods:{
    gotoback(){
      this.$router.push(this.backurl)
    },
  }
};
</script>

<style scoped lang="scss">
.header{
  .h-left{
    background-image: url("~@/assets/images/左副标.png") ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .text{
      font-size: 16px;
      font-family: a;
      font-weight: normal;
      color: #08FCFF;
    }
  }
  .h-center{
    background-image: url("~@/assets/images/主标.png") ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .text{
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 33px;
      background: linear-gradient(0deg, #7ED7FB 0%, #FFFFFF 100%);
      -webkit-background-clip: text;
      line-height: 1em;
      -webkit-text-fill-color: transparent;
      padding-top: 5px;
      font-family: Source Han Sans CN;
      color: #DBE5FA;
    }
  }
  .h-right{
    background-image: url("~@/assets/images/右副标.png") ;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    .text{
      font-size: 16px;
      font-family: a;
      font-weight: normal;
      color: #08FCFF;
    }
  }
}
</style>
