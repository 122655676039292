<template>
<my-dv-box class="statics" top="115px" x-align="center" width="886px" height="68px">
  <div class="box" v-if="area">
    <div class="item">
      <el-image :src="require('@/assets/images/tongji-1.png')" style="width: 54px;height: 68px"></el-image>
      <div class="right">
        <div class="name">总面积</div>
        <div class="value">{{area.grossArea}}<span>亩</span></div>
      </div>
    </div>
    <div class="item">
      <el-image :src="require('@/assets/images/tongji-2.png')" style="width: 54px;height: 68px"></el-image>
      <div class="right">
        <div class="name">总产值</div>
        <div class="value">{{area.grossValueOfProduction}}<span>万元</span></div>
      </div>
    </div>
    <div class="item">
      <el-image :src="require('@/assets/images/tongji-3.png')" style="width: 54px;height: 68px"></el-image>
      <div class="right">
        <div class="name">总产量</div>
        <div class="value">{{area.totalOutput}}<span>吨</span></div>
      </div>
    </div>
<!--    <div class="item">-->
<!--      <el-image :src="require('@/assets/images/tongji-4.png')" style="width: 54px;height: 68px"></el-image>-->
<!--      <div class="right">-->
<!--        <div class="name">总销量</div>-->
<!--        <div class="value">1<span>元</span></div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</my-dv-box>
</template>

<script>
export default {
  name: "statics",
  data(){
    return {
      res:null
    }
  },
  props:{
    area:null,
  },
  mounted() {
    setTimeout(i=>{
      this.res={
        sumArea:13880,
        sumTend:4257,
        sumValue:310
      }
    },2000);
    // this.$http.get('/gongsidaping/gk/sum').then(res=>{
    //   this.res=res.data
    // })
  }
};
</script>

<style scoped lang="scss">
.statics{
  .box{
    width: 100%;height: 100%;
    display: flex;justify-content: space-between;
    .item{
      height: 100%;
      display: flex;
      align-items: center;
      .right{
        height: 60px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        align-items: flex-start;
        font-family: a;
        font-weight: 400;
        color: #FFFFFF;
        text-align: left;
        margin-left: 17px;
        .name{
          font-size: 21px;
        }
        .value{
          font-size: 26px;
          span{
            font-size: 16px;
            margin-left: 8px;
          }
        }
      }
    }
  }
}
</style>
