<template>
  <my-dv-box width="440px" height="484px" top="255px" >
    <ws-title title="尾水监测"  right="更多" @click="gotoam"></ws-title>
    <my-dv-box class="weishuizhibiao" top="50px" width="434px" height="115px">
      <div style="padding: 9px 0 0 9px;">尾水排放限值:</div>
      <div class="is-left">
        <div class="xed">
          <div>悬浮物</div>
          <div>一级（≤40）</div>
          <div>二级（≤85）</div>
        </div>
        <div class="xed">
          <div>总氮</div>
          <div>一级（≤3.0）</div>
          <div>二级（≤6.0）</div>
        </div>
        <div class="xed">
          <div>高锰酸盐</div>
          <div>一级（≤15）</div>
          <div>二级（≤30）</div>
        </div>
        <div class="xed">
          <div>总磷</div>
          <div>一级（≤0.4）</div>
          <div>二级（≤0.8）</div>
        </div>
        <div class="xed">
          <div>pH</div>
          <div>一级（≤6）</div>
          <div>二级（≤9）</div>
        </div>
      </div>
    </my-dv-box>

    <my-dv-box class="weim" left="0" bottom="0" width="400px" height="308px">
      <my-dv-box x-align="left" height="84px">
        <div class="t">
          <ws-webpdongtu>
            <el-image slot="" class="imaget" :src="require('@/assets/images/zdan1.svg')"></el-image>
          </ws-webpdongtu>
          <div>
            <div class="titl">总氮</div>
            <div class="text">{{tailwaterDetectionInfo.nitrogen}}mg/l</div>
          </div>
        </div>
      </my-dv-box>
      <my-dv-box x-align="right" height="84px">
        <div class="t">
          <ws-webpdongtu>
              <el-image slot="" class="imaget" :src="require('@/assets/images/zdan.svg')"></el-image>
          </ws-webpdongtu>
          <div>
            <div class="titl">总磷</div>
            <div class="text">{{tailwaterDetectionInfo.phosphorus}}mg/l</div>
          </div>
        </div>
      </my-dv-box>

      <my-dv-box x-align="left" y-align="bottom" height="84px">
        <div class="t">
          <ws-webpdongtu>
            <el-image slot="" class="imaget" :src="require('@/assets/images/tph.svg')"></el-image>
          </ws-webpdongtu>
          <div>
            <div class="titl">pH值</div>
            <div class="text">{{tailwaterDetectionInfo.ph}}</div>
          </div>
        </div>
      </my-dv-box>

      <my-dv-box x-align="right" y-align="bottom" height="84px">
        <div class="t">
          <ws-webpdongtu>
            <el-image slot="" class="imaget" :src="require('@/assets/images/txuanfu.svg')"></el-image>
          </ws-webpdongtu>
          <div>
            <div class="titl">悬浮物</div>
            <div class="text">{{tailwaterDetectionInfo.suspendedMatter}}mg/l</div>
          </div>
        </div>
      </my-dv-box>

      <my-dv-box x-align="center" y-align="middle" height="84px">
        <div class="t">
          <ws-webpdongtu>
            <el-image slot="" class="imaget" :src="require('@/assets/images/tgms.svg')"></el-image>
          </ws-webpdongtu>
          <div>
            <div class="titl">高锰酸盐</div>
            <div class="text">{{tailwaterDetectionInfo.potassiumPermanganate}}mg/l</div>
          </div>
        </div>
      </my-dv-box>
    </my-dv-box>
  </my-dv-box>
</template>

<script>
import WsTitle from "@/views/component/ws/ws-title";
import WsWebpdongtu from "@/views/component/ws/ws-webpdongtu";
export default {
  name: "weishuijiance",
  components: {WsWebpdongtu, WsTitle},
  props:{tailwaterDetectionInfo:null,},
  data(){
    return {
      d:{
        "potassiumPermanganate": "15",
        "suspendedMatter": "40",
        "nitrogen": "3.0",
        "ph": "7.5",
        "phosphorus": "0.4"
      }
    }
  },
  methods:{
    gotoam(){
      this.$router.push('/ambient')
    },
  }
};
</script>

<style scoped lang="scss">
.weishuizhibiao{
  background: linear-gradient(182deg, #015383 0%, #020F2A 100%);
  border-radius: 15px;
  font-size: 16px;
  font-family: Source Han Sans CN;
  font-weight: bold;
  color: #FFFFFF;
  line-height: 24px;
  text-align: left;
  .is-left{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    padding: 0 0 0 9px;
    .xed{
      width: 20%;
      >div{
        text-align: center;
        font-size: 14px;
        font-weight: 500;
        width: 100%;
        &:not(:first-child){
          font-size: 12px;
        }
      }
    }

    .mgr24{
     margin-right: 24px;
    }
  }
}
.weim{
  background: url("~@/assets/images/底.png") no-repeat;
  background-size: 100% 100%;
  .t{
    height: 100%;
    display: flex;
    align-items: center;
    .image{
      width: 85px;
      height: 94px;
    }
    .titl{
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #58CDFF;
      line-height: 30px;
    }
    .text{
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #ffffff;
    }
  }
}
</style>
